import React from 'react'
import PropTypes from 'prop-types'
import { PortionMain } from './index.style'

const PortionBar = ({ width, bgColor }) => {
  return <PortionMain width={width} bgColor={bgColor} />
}

PortionBar.propTypes = {
  width: PropTypes.number,
  bgColor: PropTypes.string,
}

export default PortionBar
