import React from 'react'
import PropTypes from 'prop-types'

import { LearnMoreItems, LearnMoreMain, LearnMoreTitle } from './index.style'
import RoundedBackground from '../../RoundedBackground'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import { Heading2, Heading4, TextBodyLarge } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import Accordion from '../../Accordion'

const LearnMore = ({ items }) => (
  <LearnMoreMain>
    <RoundedBackground />

    <Container>
      <Spacer size={[80, 180]} />

      <Grid>
        <GridItem tabletP={12} tabletPStart={3} tabletL={6} tabletLStart={2}>
          <LearnMoreTitle>
            <Heading2>
              <AnimateSplitText>Learn More</AnimateSplitText>
            </Heading2>
            <Spacer size={[30, 30]} />
            <TextBodyLarge>
              Even more details about how this portfolio works!
            </TextBodyLarge>
          </LearnMoreTitle>
        </GridItem>

        <GridItem tabletP={12} tabletPStart={3} tabletL={7} tabletLStart={9}>
          <LearnMoreItems>
            {React.Children.toArray(
              items.map((item, itemIndex) => (
                <Accordion
                  openStart={itemIndex === 0 ? true : false}
                  toggle={
                    <Heading4 as="span">
                      <AnimateSplitText>{item.question}</AnimateSplitText>
                    </Heading4>
                  }
                >
                  <TextBodyLarge>
                    {' '}
                    <AnimateSplitText>{item.answer}</AnimateSplitText>{' '}
                  </TextBodyLarge>
                </Accordion>
              ))
            )}
          </LearnMoreItems>
        </GridItem>
      </Grid>

      <Spacer size={[80, 180]} />
    </Container>
  </LearnMoreMain>
)

LearnMore.propTypes = {
  items: PropTypes.array.isRequired,
}

export default LearnMore
