import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { navigate } from 'gatsby'

import Spacer from '../../components/Spacer'
import Seo from '../../components/Seo'
import ChartComponent from '../../components/graph'
import Holdings from '../../components/Portfolio/Holdings'
import About from '../../components/Portfolio/About'
import Video from '../../components/Resources/Video'
import LearnMore from '../../components/Portfolio/LearnMore'
import { processChartData } from '../../utils/portfolioutils'
import {
  HoldingsChartURI,
  HoldingsURI,
  PortfolioFaqURI,
} from '../../constants/portfolio-urls'
import {
  AboutData,
  PortfolioVideoText,
} from '../../constants/portfolio-constants'
import { Spinner } from '../../components/graph/Spinner'
import { getDataFromAPI } from '../../utils/serviceUtils'

const VIDEO_MAP = { 30: 'uDIGSGkQJhM', 34: 'vvpavHwkPRY' }

const Portfolio = ({ params }) => {
  const [holdings, setHoldings] = useState()
  const [about, setAbout] = useState('')
  const [learnMoreItems, setLearnMoreItems] = useState([])
  const [factCardLink, setFactCardLink] = useState(null)
  const [contractAddress, setContactAddress] = useState('')
  const [chainName, setChainName] = useState('')
  const [videoUrlId, setVideoUrlId] = useState(null)
  const [aboutPortfolio, setAboutPorfolio] = useState([])
  const [chartData, setChartData] = useState([])
  const [percentageData, setPercentageData] = useState([])
  const [range, setRange] = useState('All')
  const [isLoading, setIsLoading] = useState(false)
  const [portfolioName, setPortfolioName] = useState('')

  const handleRangeChange = rangeValue => {
    setRange(rangeValue)
  }

  const fetchData = useCallback(async () => {
    if (!params.id) {
      navigate('/404', { replace: true })
      return
    }
    const holdingsDataPromise = getDataFromAPI(HoldingsURI)
    const portfolioFqaPromise = getDataFromAPI(
      `${PortfolioFaqURI}${params.id}/`
    )
    const holdingsChartDataPromise = getDataFromAPI(
      `${HoldingsChartURI}${params.id}/`
    )

    try {
      setIsLoading(true)
      const [holdingsData, faqData, chartData] = await Promise.all([
        holdingsDataPromise,
        portfolioFqaPromise,
        holdingsChartDataPromise,
      ])
      const parsedData = await holdingsData.json()
      const parsedFaqData = await faqData.json()
      const parsedChartData = await chartData.json()
      const { dataPoints, percentageDataPoints } = parsedChartData

      setChartData(dataPoints || [])
      setPercentageData(percentageDataPoints)

      const currentPortfolio =
        parsedData.portfolioExamples.find(
          portfolio => portfolio.portfolioExampleId == params.id
        ) ||
        parsedData.portfolioExamplesAlternate.find(
          portfolio => portfolio.portfolioExampleId == params.id
        )

      if (!currentPortfolio) {
        navigate('/404', { replace: true })
        return
      }

      setLearnMoreItems(parsedFaqData)
      const currentHoldings = currentPortfolio.allocation[0]
      setHoldings(
        currentHoldings.internalConfigurations?.sort(
          (a, b) => b.percentage - a.percentage
        )
      )
      const symbol = currentHoldings.coinName
      const noOfHoldings = currentHoldings.internalConfigurations?.length || 0

      const computedData = AboutData.map(item => {
        if (item.key === 'symbol') {
          return { ...item, content: symbol?.toUpperCase() }
        } else if (item.key === 'noOfHoldings') {
          return { ...item, content: noOfHoldings }
        } else if (item.key === 'performanceStartDate') {
          return {
            ...item,
            content: moment(new Date(currentPortfolio[item.key]))
              .tz('UTC')
              .format('MM/DD/yyyy'),
          }
        }
        return { ...item, content: currentPortfolio[item.key] }
      }).filter(compItem => !!compItem.content)
      const portfolioExampleId = currentPortfolio.portfolioExampleId
      setVideoUrlId(VIDEO_MAP[portfolioExampleId])
      setAboutPorfolio(computedData)
      setPortfolioName(currentPortfolio.name)
      setAbout(currentPortfolio.managedMessageV2)
      setFactCardLink(currentPortfolio.factCardLink)
      setChainName(currentPortfolio.chainName)
      setContactAddress(currentPortfolio.contractAddress)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [params.id])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchData()
  }, [fetchData])

  if (isLoading) {
    return (
      <>
        <Spacer size={[200, 250]} />
        <Spinner />
      </>
    )
  }

  return (
    <>
      <Seo title={`Portfolio ${portfolioName}`} />

      <Spacer size={[100, 150]} />

      <ChartComponent
        name={portfolioName}
        onRangeChange={handleRangeChange}
        data={processChartData(chartData[range])}
        percentageData={processChartData(percentageData[range])}
        range={range}
      />

      <Holdings title="Portfolio Holdings" items={holdings} />

      <Spacer size={[60, 60]} />

      <About
        title="About"
        contractAddress={contractAddress}
        chainName={chainName}
        factCardLink={factCardLink}
        text={about}
        items={aboutPortfolio}
      />

      {!!videoUrlId && <Video text={PortfolioVideoText} videoId={videoUrlId} />}

      <LearnMore items={learnMoreItems} />
    </>
  )
}

export default Portfolio
