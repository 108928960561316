import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const HoldingsMain = styled.div`
  position: relative;
  overflow: hidden;
`

export const AboutWrapper = styled.div`
  position: relative;
`

export const HoldingsRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
`

export const CoinSymbol = styled.span`
  text-transform: uppercase;
`

export const CoinLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${mq.tiny} {
    gap: 10px;
  }
  ${mq.mobile} {
    gap: 12px;
  }
  ${mq.mobileL} {
    gap: 16px;
  }
  ${mq.desk} {
    gap: 20px;
  }
`

export const Parabola = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.bgColor};
  ${mq.tiny} {
    width: 12px;
    height: 12px;
  }
  ${mq.mobile} {
    width: 12px;
    height: 12px;
  }
  ${mq.mobileL} {
    width: 16px;
    height: 16px;
  }
  ${mq.desk} {
    width: 20px;
    height: 20px;
  }
`
