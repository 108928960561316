import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { AccordionMain } from '../../Accordion/index.style'

export const LearnMoreMain = styled.div`
  position: relative;
`

export const LearnMoreTitle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const LearnMoreDots = styled.div`
  position: relative;
  display: none;
  flex: 1;
  ${clamp('margin-top', 24, 64)}

  ${mq.tabletL} {
    display: block;
  }
`

export const LearnMoreDotsInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const LearnMoreItems = styled.div`
  ${AccordionMain}:first-child {
    ${mq.tabletL} {
      padding-top: 0;
    }
  }
`
