import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import { Heading4, HoldingsLabel } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import PartitionBar from '../../PartitionBar'
import PortionBar from '../../PortionBar'
import {
  HoldingsMain,
  CoinLabelContainer,
  Parabola,
  HoldingsRow,
  CoinSymbol,
} from './index.style'
import { getCoinColor } from '../../../utils/getcoincolor'

const Holdings = ({ title, items }) => (
  <HoldingsMain>
    <Container>
      <Spacer size={[90, 50]} />
      <Grid>
        <GridItem tabletP={14} tabletPStart={2} tabletL={14} tabletLStart={2}>
          <AnimateSplitText>
            <Heading4>{title}</Heading4>
          </AnimateSplitText>
          <Spacer size={[20, 20]} />
          <AnimateFadeIn>
            <PartitionBar height={0.5}>
              {Array.isArray(items) &&
                items?.map(({ coinName, percentage }) => (
                  <PortionBar
                    key={coinName}
                    width={percentage}
                    bgColor={getCoinColor(coinName)}
                  />
                ))}
            </PartitionBar>
          </AnimateFadeIn>
          <Spacer size={20} />
          <AnimateSlideIn>
            <div>
              {Array.isArray(items) &&
                items?.map(item => (
                  <HoldingsRow key={item.coinName}>
                    <CoinLabelContainer
                      onClick={() =>
                        navigate(`/coins/${item.coinName}`, {
                          state: { data: item },
                        })
                      }
                      aria-hidden
                    >
                      <Parabola bgColor={getCoinColor(item.coinName)} />
                      <HoldingsLabel>
                        {item.coinNiceName} /
                        <CoinSymbol>{item.coinName}</CoinSymbol>
                      </HoldingsLabel>
                    </CoinLabelContainer>
                    <HoldingsLabel>{item.percentage.toFixed(2)}%</HoldingsLabel>
                  </HoldingsRow>
                ))}
            </div>
          </AnimateSlideIn>
        </GridItem>
      </Grid>
    </Container>
  </HoldingsMain>
)

Holdings.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  items: PropTypes.array,
}

export default Holdings
