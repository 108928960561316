import React from 'react'
import { number } from 'prop-types'

import { BarMain } from './index.style'

const PartitionBar = ({ children, height = 0.75, borderRadius = 0.75 }) => {
  return (
    <BarMain height={height} borderRadius={borderRadius}>
      {children}
    </BarMain>
  )
}

PartitionBar.propTypes = {
  height: number,
  borderRadius: number,
}

export default PartitionBar
